.menu-items{
    background-color: #FBF7EE;
    padding: 50px 0;

    h3{
        color: #c2272c;
    }

    li{
        display: flex;
        justify-content: baseline;
        padding: 20px 0;
    }

    .item{
        width: 50%;
    }

    .item-price{
        width: 50%;
        text-align: right;
        background-image: linear-gradient(to right, black 20%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 5px 2px;
        background-repeat: repeat-x;
        position: relative;

        span{
            color: #c2272c;
            font-size: 1.8rem;
            position: absolute;
            padding: 0px 10px;
            bottom: 0;
            right: 0;
            background-color: #FBF7EE;
        }
    }
}