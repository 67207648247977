footer {
  padding: 60px 0;
  color: #ffffff;
  background-color: #c2272c;
  text-align: center;

  @media screen and (min-width: 48rem) {
    text-align: left;
  }

  p {
    color: #ffffff;
    line-height: 3rem;
  }

  strong{
    font-size: 2rem;
  }

  .details {
    margin-bottom: 60px;
  }

  @media screen and (min-width: 48rem) {
    .details {
      margin-bottom: 0px;
    }
  }
  a {
    color: white;

    &:hover {
      color: white;
      text-decoration: underline;
    }

    &:active, &:visited {
      color: white;
    }
  }

  .footer-nav-wrapper{
    display: none;
    margin: 80px auto;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    @media screen and (min-width: 48rem) {
      display: block;
    }

    .footer-nav {
      display: flex;
      flex-direction: row;
      width: 80%;
      margin: 0 auto;
    }
  }

  li {
    font-size: 1.4rem;
    list-style-type: none;
    margin: 25px auto;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .footer-links {
    text-align: center;
    @media screen and (min-width: 48rem) {
      text-align: right;
    }
  }

  .footer-links img {
    margin-left: 10px;
    display: inline-block;
    transition: 200ms all ease-in-out;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    }
  }

  .seperator {
    @media screen and (min-width: 48rem) {
      border: 0;
      border-left: 2px solid rgba(255,255,255,0.3);
      padding-left: 30px;
    }

  }

  .dropdown{
    
    li{
      color: #ffffff;
    }

    .dropdown-content li {
      float: none;
      padding: 8px 4px;
      text-decoration: none;
      display: block;
      text-align: center;
      margin: 0;
      a{ 
        color: #707070;
      }
    }
  

  .dropdown-content {
    display: none;
    position: relative;
    background-color: #ffffff;
    border-top: 2px solid #c2272c;
    border-bottom: 2px solid #c2272c;
    padding: 10px 0;
    width: 100%;
    z-index: 1;
    transition: 200ms all;
    
    @media screen and (min-width: 48rem) {
      display: none;
      position: absolute;
      padding: 10px 0;
      min-width: 160px;
      top: 50px;
      left: -55px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      z-index: 1;
      transition: 200ms all;
      text-transform: uppercase;
    }
}
}
}