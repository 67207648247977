/* Feedback Section */

form {
  text-align: left;
  max-width: 450px;
  margin: 40px auto;
}

input {
  -webkit-appearance: none;
  padding: 0;
  border-radius: 0;
  border: 1px solid red;
}

.feedback-section {
  text-align: center;
  padding: 50px 0;
  background-color: #fbf7ee;
  p{
    max-width: 450px;
  }
  h2 {
    color: #c2272c;
    font-size: 4.6rem;
  }
  .btn{
    margin: 30px auto 0;
    display: block;
    max-width: 120px;
    &:hover{
      background-color: #A0161A; 
    }
  }
}
