body{
  &.fixed{
    overflow:hidden;

    @media screen and (min-width: 62rem) {
      overflow:auto;
    }
  }
}

/* Navigation */
nav {
  position: fixed;
  width: 100%;
  background-color: #fefdf9;
  z-index: 999;
  top: 0;
  transition: 200ms all;

  @media screen and (min-width: 62rem) {
    &.scrolled {
      // background-color: rgba(255, 255, 255, 0.96);
      .navbar-icon {
        max-width: 120px;
      }
    }
  }
}

.navbar {
  margin: 0 auto;
  background-color: #fefdf9;
  display: block;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 62rem) {
    background-color: #fefdf9;
    display: flex;
  }

  @media screen and (max-width: 62rem) {
    width: 100%;
  }

  a {
    color: #707070;
  }

  &.container {
    padding: 0;
  }

  &.display{
    min-height: 100vh;
    overflow-y: scroll;
    @media screen and (min-width: 62rem) {
      min-height: auto;
      overflow-y: auto;
      }
  }

}

.navbar-icon {
  max-width: 250px;
  padding: 25px 15px;
  transition: 500ms all ease-out;
  display: inline-block;

  img {
    max-width: 180px;
    width: 100%;
  }
}

.navbar-mobile{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 62rem) {
    width: auto;
    display: block;
  }
}

.hamburger{
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 20px;
  margin-right: 20px;
  display: block;
  background-repeat: no-repeat;
  background: url(../images/hamburger.svg);
  @media screen and (min-width: 62rem) {
    display: none;
  }
  &.active{
    background: url(../images/hamburger-close.svg);
  }
}

.navbar-items {
  display: none;
  padding: 10px 0;
  justify-content: flex-end;
  flex-grow: 1;
  text-transform: uppercase;
  letter-spacing: 2px;
  flex-direction: column;
  text-align: center;
  -webkit-box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.2);

  &.display{
    display: flex;
  }

  @media screen and (min-width: 62rem) {
    display: flex;
    padding: 0px;
    flex-direction: row;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  li {
    font-size: 1.4rem;
    list-style-type: none;
    padding: 20px 0;
    font-weight: bold;

    @media screen and (min-width: 62rem) {
      margin-left: 35px;
    }

    a {
      transition: 200ms all;
      padding-bottom: 10px;
      border-bottom: 2px solid transparent;
      text-decoration: none;
    }
  }

  li:hover {
    a {
      color: #c2272c;
      border-bottom: 2px solid #c2272c;
    }
  }

  li.active {
    a {
      color: #c2272c;
      border-bottom: 2px solid #c2272c;
    }
  }

  .seperator {
    border-top: 1px solid #d8d4b0;

    @media screen and (min-width: 62rem) {
      border: 0;
      border-left: 1px solid #d8d4b0;
      padding-left: 30px;
    }

  }
}


/* Dropdown Menu for desktop */
.dropdown {
  
  li{
    color: #707070;

    a:hover{
      color: #c2272c;
      border-bottom: 2px solid #c2272c;
    }

  }
  
  &:hover .dropdown-content {
    @media screen and (min-width: 62rem) {
      display: block;
    }
  }

  .dropdown-content li {
    float: none;
    padding: 20px 16px;
    text-decoration: none;
    display: block;
    text-align: center;
    margin: 0;
    @media screen and (min-width: 62rem) {
      padding: 14px 16px;
    }
  }

  .dropdown-content {
    display: none;
    position: relative;
    background-color: #ffffff;
    border-top: 2px solid #c2272c;
    border-bottom: 2px solid #c2272c;
    padding: 10px 0;
    width: 100%;
    z-index: 1;
    transition: 200ms all;

    &.toggle{
      display:block;
      @media screen and (min-width: 62rem) {
        display: inherit;
      }
    }
    
    @media screen and (min-width: 62rem) {
      display: none;
      position: absolute;
      border-top: 2px solid #c2272c;
      padding: 30px 0;
      min-width: 250px;
      top: 50px;
      left: -60px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      z-index: 1;
      transition: 200ms all;
    }
  }
}
