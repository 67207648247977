.feature-title {
  position: relative;
  padding: 80px 0;
  text-align: center;

  a{
    text-decoration: underline;
    color: #c2272c;
  }
}

.feature-menu{
  background-color: #c2272c;    
  position: relative;
  padding: 30px 0;
  text-align: center;

  hr{
    background-color: white;
  }
  h1, h2, p{
    color: white;
  }
}

.feature-white {
  h2 {
    color: #c2272c;
  }
}

.feature-green {
  background-color: #87b17b;
  padding: 40px 0;

  p,
  h1,
  h2,
  h3 {
    color: white;
  }
}

.feature-grey {
  background-color: #f3f3f3;
}

.feature-corner {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  
  @media screen and (min-width: 48rem) {
    display: block;
  }

  &.top {
    background-image: url(./../images/spice-tl.png);
  }

  &.bottom {
    background-image: url(./../images/spice-br.png);
    background-position: bottom right;
  }
}

.feature-pattern {
  position: none;
  background-image: url(./../images/spices.png);
  top: 0;
  bottom: 0;
  width: 20vw;
  background-size: 110%;
  opacity: 0.4;

  @media screen and (min-width: 48rem) {
    position: absolute;
  }

  &.flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
    background-position: 50% 50%;

  }
}
