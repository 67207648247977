/* Slider */
.slider-single {
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 48rem) {
    width: 85%;
    margin-right: 0;

    .slick-slide {
      padding: 20px;
    }
  }
}

.slick-slider {

  .slick-prev:before,
  .slick-next:before {
    display: block;
    content: ' ';
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }

  .slick-prev:before {
    background-image: url('../images/left-arrow.svg');
  }

  .slick-next:before {
    background-image: url('../images/right-arrow.svg');
  }

  .slick-prev,
  .slick-next {
    z-index: 99;
    height: 40px;
    width: 40px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }

  @media screen and (min-width: 48rem) {
    .slick-prev {
      left: 0px;
    }

    .slick-next {
      right: 0px;
    }
  }
}

.feature-slider {
  text-align: center;

  p {
    font-size: 1.8rem;
    line-height: 3rem;
  }

  h2 {
    font-size: 4rem;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 2.5rem;
    margin-top: 0;
  }
}


.slider-three {

  .slick-center {
    @media screen and (min-width: 48rem) {
      img {
        transform: scale(1.5);
        transition: all 300ms ease;
      }
    }
  }

  .slider-img {
    width: 100%;
    padding: 0px;

    @media screen and (min-width: 48rem) {
      width: 100%;
      height: auto;
      padding: 50px;

    }
  }
}

.slider-single .slider-img {
  width: 100%;

  @media screen and (min-width: 48rem) {
    width: 100%;
    height: auto;
    border: 10px solid white;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  }
}
